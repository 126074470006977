import Icon, { ExportOutlined } from '@ant-design/icons';
import { Button, Descriptions, Space, Table, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import taskConversionProcessFileApi from '../../../../../api/TaskConversionProcessFileApi';
import taskConversionProcessProjectApi from '../../../../../api/TaskConversionProcessProjectApi';
import FileSizeComponent from '../../../../../components/FileSizeComponent/FileSizeComponent';
import LayoutComponent from '../../../../../components/LayoutComponent/LayoutComponent';
import TaskStatusComponent from '../../../../../components/TaskStatusComponent/TaskStatusComponent';
import { Page } from '../../../../../model/elements';
import { TaskConversionProcessFile, TaskConversionProcessProject } from '../../../../../model/entities';
import { TaskStatusType } from '../../../../../model/types';
import { ReactComponent as BackSvg } from '../../../../../resources/images/back.svg';
import { ReactComponent as RefreshSvg } from '../../../../../resources/images/refresh.svg';
import notificationService from '../../../../../services/NotificationService';
import tableService from '../../../../../services/TableService';
import styles from './TaskConversionProcessFilesPage.module.scss';

/**
 * Returns the task conversion process files page.
 * @returns the task conversion process files page.
 */
const TaskConversionProcessFilesPage = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const params = useParams<ParamsType>();
    const [task, setTask] = useState<TaskConversionProcessProject>();
    const [taskConversionProcessFilesPage, setTaskConversionProcessFilesPage] =
        useState<Page<TaskConversionProcessFile>>();
    const [loading, setLoading] = useState<boolean>();
    const [searchText, setSearchText] = useState<string>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true);
                const task = await taskConversionProcessProjectApi.get(+params.id);
                setTask(task);

                await listTaskConversionProcessFiles(0, tableService.pageSize, 'name', true, task.id!);
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(false);
            }
        };
        init();
    }, [intl, params.id]);

    /*** METHODS ***/

    const refresh = async () => {
        try {
            setLoading(true);
            const task = await taskConversionProcessProjectApi.get(+params.id);
            setTask(task);

            const page = 0;
            const size = taskConversionProcessFilesPage?.size || tableService.pageSize;
            const sortField = taskConversionProcessFilesPage?.sort.field || 'name';
            const sortOrder = taskConversionProcessFilesPage?.sort.order || true;
            await listTaskConversionProcessFiles(page, size, sortField, sortOrder, task.id!, searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const paginate = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        try {
            setLoading(true);
            const page = pagination.current! - 1;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            await listTaskConversionProcessFiles(page, pageSize, sortField, sortOrder, task!.id!, searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const search = async (searchText: string) => {
        try {
            setLoading(true);
            setSearchText(searchText);

            const page = 0;
            const pageSize = taskConversionProcessFilesPage!.size;
            const sortField = taskConversionProcessFilesPage!.sort.field!;
            const sortOrder = taskConversionProcessFilesPage!.sort.order!;
            await listTaskConversionProcessFiles(page, pageSize, sortField, sortOrder, task!.id!, searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const listTaskConversionProcessFiles = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        taskId: number,
        searchText?: string,
    ) => {
        const taskMemberActionsPage = await taskConversionProcessFileApi.list(
            page,
            size,
            sortField,
            sortOrder,
            taskId,
            searchText,
        );
        setTaskConversionProcessFilesPage(taskMemberActionsPage);
    };

    /*** COMPONENTS ***/

    const placeholder: string = intl.formatMessage({ id: 'taskConversionProcessFiles.search' });
    const items = taskConversionProcessFilesPage ? taskConversionProcessFilesPage.content : [];
    const columns: ColumnsType<TaskConversionProcessFile> = [
        {
            title: <FormattedMessage id="taskConversionProcessFile.name" />,
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            defaultSortOrder: 'ascend',
            render: (value: string) => {
                const projectId = task ? task.projectId?.replace('b.', '') : undefined;
                const url: string = `https://acc.autodesk.eu/docs/files/projects/${projectId}`;
                return (
                    <a href={url} target="_blank" rel="noreferrer noopener">
                        {value}
                        <ExportOutlined className={styles.navigationIcon} />
                    </a>
                );
            },
        },
        {
            title: <FormattedMessage id="taskConversionProcessFile.size" />,
            dataIndex: 'size',
            key: 'size',
            width: 140,
            align: 'right',
            sorter: true,
            render: (value: number) => <FileSizeComponent value={value} />,
        },
        {
            title: <FormattedMessage id="taskConversionProcessFile.audit.created" />,
            dataIndex: 'auditCreated',
            key: 'created',
            width: 240,
            align: 'center',
            render: (value: string, taskConversionProcessFile: TaskConversionProcessFile) => (
                <FormattedDate
                    value={taskConversionProcessFile.audit!.created as any}
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                    second="2-digit"
                />
            ),
        },
        {
            title: <FormattedMessage id="taskConversionProcessFile.audit.updated" />,
            dataIndex: 'auditUpdated',
            key: 'updated',
            width: 240,
            align: 'center',
            render: (value: string, taskConversionProcessFile: TaskConversionProcessFile) => (
                <FormattedDate
                    value={taskConversionProcessFile.audit!.updated as any}
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                    second="2-digit"
                />
            ),
        },
        {
            title: <FormattedMessage id="taskConversionProcessFile.status" />,
            dataIndex: 'status',
            key: 'status',
            width: 200,
            align: 'center',
            render: (status: TaskStatusType) => <TaskStatusComponent status={status} />,
        },
        {
            title: <FormattedMessage id="taskConversionProcessFile.customAttributeStatus" />,
            dataIndex: 'customAttributeStatus',
            key: 'customAttributeStatus',
            width: 200,
            align: 'center',
            render: (status: TaskStatusType) => <TaskStatusComponent status={status} />,
        },
    ];

    return (
        <LayoutComponent pageId="tasks">
            <Descriptions
                title={
                    <>
                        <span className={styles.title}>
                            #{task?.id} - {task?.name}
                        </span>
                        <TaskStatusComponent status={task?.status} />
                        <Space>
                            <Tooltip title={<FormattedMessage id="button.back" />}>
                                <Link to={`/task-conversion-projects/${params.id}`}>
                                    <Button type="ghost" size="small" icon={<Icon component={BackSvg} />} />
                                </Link>
                            </Tooltip>
                            <Tooltip title={<FormattedMessage id="button.refresh" />}>
                                <Button
                                    type="ghost"
                                    size="small"
                                    icon={<Icon component={RefreshSvg} />}
                                    onClick={refresh}
                                />
                            </Tooltip>
                        </Space>
                    </>
                }
                column={2}
                size="small"
                className={styles.summary}
            >
                <Descriptions.Item label={<FormattedMessage id="taskConversionProcessProject.projectName" />} span={2}>
                    {task?.projectName}
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="task.audit.created" />}>
                    <FormattedDate
                        value={task?.audit?.created as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="task.audit.updated" />}>
                    <FormattedDate
                        value={task?.audit?.updated as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Descriptions.Item>
                <Descriptions.Item>{task?.description}</Descriptions.Item>
            </Descriptions>
            <div className="toolbar">
                <Search placeholder={placeholder} onSearch={search} size="large" className="search" />
            </div>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(taskConversionProcessFilesPage, {
                    position: ['bottomRight'],
                })}
                rowKey="id"
                onChange={paginate}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading}
            />
        </LayoutComponent>
    );
};

export default TaskConversionProcessFilesPage;

type ParamsType = { id: string };
