import Icon, { ExportOutlined } from '@ant-design/icons';
import { Button, Descriptions, Space, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import taskConversionProcessProjectApi from '../../../../api/TaskConversionProcessProjectApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import TaskStatusComponent from '../../../../components/TaskStatusComponent/TaskStatusComponent';
import { TaskConversionProcessProject } from '../../../../model/entities';
import { TaskStatusType } from '../../../../model/types';
import { ReactComponent as BackSvg } from '../../../../resources/images/back.svg';
import { ReactComponent as RefreshSvg } from '../../../../resources/images/refresh.svg';
import notificationService from '../../../../services/NotificationService';
import tableService from '../../../../services/TableService';
import styles from './TaskConversionProcessProjectPage.module.scss';

/**
 * Returns the task conversion process project page.
 * @returns the task conversion process project page.
 */
const TaskConversionProcessProjectPage = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const params = useParams<ParamsType>();
    const [task, setTask] = useState<TaskConversionProcessProject>();
    const [loading, setLoading] = useState<boolean>();

    /*** EFFECTS ***/

    const init = useCallback(async () => {
        try {
            setLoading(true);
            const task = await taskConversionProcessProjectApi.get(+params.id);
            setTask(task);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    }, [intl, params.id]);

    useEffect(() => {
        init();
    }, [init]);

    /*** METHODS ***/

    /*** COMPONENTS ***/

    const items = task ? [task] : [];
    const columns: ColumnsType<TaskConversionProcessProject> = [
        {
            title: <FormattedMessage id="taskConversionProcessProject.projectName" />,
            dataIndex: 'projectName',
            key: 'projectName',
            render: (value: string) => {
                const projectId = task ? task.projectId?.replace('b.', '') : undefined;
                const url: string = `https://acc.autodesk.eu/docs/files/projects/${projectId}`;
                return (
                    <a href={url} target="_blank" rel="noreferrer noopener">
                        {value}
                        <ExportOutlined className={styles.navigationIcon} />
                    </a>
                );
            },
        },
        {
            title: <FormattedMessage id="taskConversionProcessProject.audit.created" />,
            dataIndex: 'auditCreated',
            key: 'created',
            width: 200,
            align: 'center',
            render: (value: string, taskConversionProcessProject: TaskConversionProcessProject) => (
                <FormattedDate
                    value={taskConversionProcessProject.audit!.created as any}
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                    second="2-digit"
                />
            ),
        },
        {
            title: <FormattedMessage id="taskConversionProcessProject.audit.updated" />,
            dataIndex: 'auditUpdated',
            key: 'updated',
            width: 200,
            align: 'center',
            render: (value: string, taskConversionProcessProject: TaskConversionProcessProject) => (
                <FormattedDate
                    value={taskConversionProcessProject.audit!.updated as any}
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                    second="2-digit"
                />
            ),
        },
        {
            title: <FormattedMessage id="taskConversionProcessProject.status" />,
            dataIndex: 'status',
            key: 'status',
            width: 160,
            align: 'center',
            render: (status: TaskStatusType) => <TaskStatusComponent status={status} />,
        },
        {
            title: <FormattedMessage id="taskConversionProcessProject.filesStatus" />,
            dataIndex: 'filesStatus',
            key: 'filesStatus',
            width: 160,
            align: 'center',
            render: (status: TaskStatusType, taskConversionProcessProject: TaskConversionProcessProject) => (
                <Link to={`/task-conversion-files/${taskConversionProcessProject.id}`}>
                    <TaskStatusComponent status={status} />
                </Link>
            ),
        },
        {
            title: <FormattedMessage id="taskConversionProcessProject.customAttributeStatus" />,
            dataIndex: 'customAttributeStatus',
            key: 'customAttributeStatus',
            width: 160,
            align: 'center',
            render: (status: TaskStatusType, taskConversionProcessProject: TaskConversionProcessProject) => (
                <Link to={`/task-conversion-files/${taskConversionProcessProject.id}`}>
                    <TaskStatusComponent status={status} />
                </Link>
            ),
        },
        {
            title: <FormattedMessage id="taskConversionProcessProject.issuesStatus" />,
            dataIndex: 'issuesStatus',
            key: 'issuesStatus',
            width: 160,
            align: 'center',
            render: (status: TaskStatusType, taskConversionProcessProject: TaskConversionProcessProject) => (
                <Link to={`/task-conversion-issues/${taskConversionProcessProject.id}`}>
                    <TaskStatusComponent status={status} />
                </Link>
            ),
        },
    ];

    return (
        <LayoutComponent pageId="tasks">
            <Descriptions
                title={
                    <>
                        <span className={styles.title}>
                            #{task?.id} - {task?.name}
                        </span>
                        <TaskStatusComponent status={task?.status} />
                        <Space>
                            <Tooltip title={<FormattedMessage id="button.back" />}>
                                <Link to="/tasks">
                                    <Button type="ghost" size="small" icon={<Icon component={BackSvg} />} />
                                </Link>
                            </Tooltip>
                            <Tooltip title={<FormattedMessage id="button.refresh" />}>
                                <Button
                                    type="ghost"
                                    size="small"
                                    icon={<Icon component={RefreshSvg} />}
                                    onClick={init}
                                />
                            </Tooltip>
                        </Space>
                    </>
                }
                column={2}
                size="small"
                className={styles.summary}
            >
                <Descriptions.Item label={<FormattedMessage id="taskConversionProcessProject.projectName" />} span={2}>
                    {task?.projectName}
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="task.audit.created" />}>
                    <FormattedDate
                        value={task?.audit?.created as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="task.audit.updated" />}>
                    <FormattedDate
                        value={task?.audit?.updated as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Descriptions.Item>
                <Descriptions.Item>{task?.description}</Descriptions.Item>
            </Descriptions>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createMemoryPagination(items, undefined, true)}
                rowKey="id"
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading}
            />
        </LayoutComponent>
    );
};

export default TaskConversionProcessProjectPage;

type ParamsType = { id: string };
